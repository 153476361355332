// 教师pc
<template>
  <div class="page">
    <div class="swiper-container top-banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in banners" :key="index">
          <img :src="$baseUrl + item.cover_pc" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <!-- 中部 -->
    <div class="goods">
      <div class="title white-title">
        <h2>好成绩，从<span>严选好老师</span>开始</h2>
        <p>了解学生问题，针对性辅导，学生提升显著</p>
      </div>
      <div class="goods-box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="goods-item">
                <div class="goodlist-img">
                  <img :src="$baseUrl + item.cover_pc" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="goods-host"></div>

          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <!-- <div class="button button-white popup" @click="contact">
        好老师 抢先预约
      </div> -->
    </div>
    <!-- 中部 -->
    <!-- 老师集合图 -->
    <div class="teachers">
      <img
        :src="$baseUrl + item.cover_pc"
        v-for="(item, index) in array"
        :key="index"
      />
    </div>
    <!-- 老师集合图 -->
    <!-- 尾部 -->
    <div class="footer"></div>
  </div>
</template>
<script>
import { rotations } from "@/api/api.js";
import Swiper from "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      banners: null,
      list: null,
      array: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      rotations({ typeId: 1 }).then((res) => {
        if (res.data.code == 200) {
          this.banners = res.data.data.teachersPC0;
          this.list = res.data.data.teachersPC1;
          this.array = res.data.data.teachersPC2;
          this.$nextTick(() => {
            this.init1();
            this.init2();
          });
        }
      });
    },

    // contact() {
    //   window.open(
    //     "https://tb.53kf.com/code/client/10179248/1",
    //     "_blank",
    //     "height = 600,width = 800,status = yes,toolbar = no,menubar = no,location = no,addressbar = no,top = 200,left = 600"
    //   );
    // },

    init1() {
      new Swiper(".top-banner", {
        loop: true,
        speed: 300,
        observer: true,
        grabCursor: true,
        updateOnWindowResize: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          bulletActiveClass: "top-banner-bullet-active",
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    init2() {
      new Swiper(".goods .swiper-container", {
        loop: true,
        slidesPerView: "auto",
        autoplay: true,
        navigation: {
          nextEl: ".goods .swiper-button-next",
          prevEl: ".goods .swiper-button-prev",
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  min-width: 1200px;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

/* 头部 */
.top-banner .swiper-button-prev {
  left: 150px !important;
}

.top-banner .swiper-button-next {
  right: 150px !important;
}

.top-banner .swiper-pagination {
  bottom: 5px;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.button-white {
  color: #f9eeb6;
  background: linear-gradient(to bottom, #0b2265, #0f2f86);
}

.title {
  text-align: center;
}

.title h2 {
  color: #383839;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.title h2 span,
.title p span {
  color: #de0a3e;
}

.button {
  width: 360px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 20px;
}

.title p {
  color: #4d4d4d;
  font-size: 18px;
}

.white-title h2 {
  color: #000000;
}

.white-title h2 span {
  color: #0a2164;
}

.white-title p {
  color: #000000;
}

.popup {
  cursor: pointer;
}

/* 轮播图 */
.goods {
  background: #f7f6f6;
  /* background: url(../images/good-bg.jpg) no-repeat; */
  background-size: cover;
  padding: 100px 0 60px;
}
.goods-box {
  margin: 50px auto 0px;
  width: 1200px;
}
.goodlist-img {
  width: 990px;
  height: 580px;
  margin-left: 100px;
}

.goods .swiper-pagination-bullet {
  width: auto;
  height: auto;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid transparent;
  opacity: 1;
  background: transparent;
}

.goods .swiper-pagination-bullet-active {
  border-color: #e02a5a;
}

.goods .swiper-button-next,
.goods .swiper-button-prev {
  width: 50px;
  height: 89px;
  margin-top: -23.5px;
  z-index: 10;
  cursor: pointer;
  background-size: 50px 89px;
  outline: 0;
}

.goods .swiper-button-next {
  background-image: url(../assets/imgs//good-right.png);
  left: auto;
  right: 0px;
}

.goods .swiper-button-prev {
  background-image: url(../assets/imgs/good-left.png);
  left: 0px;
  right: auto;
}

.goods .goods-btn img {
  display: block;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 2px solid #ffffff;
}

/* 尾部 */
.footer {
  background: url(../assets/imgs/teachers_pc/2.jpg) no-repeat;
  background-size: cover;
  height: 218px;
}
.teachers {
  width: 100%;
  height: auto;
}
.teachers img {
  width: 100%;
}
</style>